<template>
  <div>
    <v-card elevation="0">
      <v-data-table :loading="loading" :items="parkingPenalties" :headers="headers" :items-per-page="5">
        <template #[`item.status`]="{ item }">
          <v-chip dark block :color="item.status == 'Aktív' ? 'success' : 'grey'" text>{{ item.status }}</v-chip>
        </template>
        <template #[`item.start_date`]="{ item }">
          <span :title="item.start_date | moment('LL')">
            {{ item.start_date | moment('ll') }}
          </span>
        </template>
        <template #[`item.end_date`]="{ item }">
          <span :title="item.end_date | moment('LL')">
            {{ item.end_date | moment('ll') }}
          </span>
        </template>
        <template #[`item.created`]="{ item }">
          <span :title="item.created | moment('LLLL')">
            {{ item.created | moment('from') }}
          </span>
        </template>
        <template #[`item.admin`]="{ item }">
          {{ item.admin?.name }}
        </template>
        <template #[`item.actions`]="{ item }">
          <!-- <v-btn icon @click="editPenalty(item)">
            <v-icon>edit</v-icon>
          </v-btn> -->
          <v-btn
            v-if="(item.status === 'Aktív' || item.status === 'Függőben')"
            fab
            small
            depressed
            class="rounded-sm"
            color="primary"
            @click="cancel(item)"
            title="Büntetés visszavonása"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-divider />

    <div class="mt-4 pb-4 d-flex justify-center">
      <v-btn color="primary" depressed @click="openPenaltyDialog">
        <v-icon left>mdi-plus</v-icon>
        Új büntetés
      </v-btn>
    </div>

    <v-dialog v-if="dialog" :value="true" persistent max-width="480px">
      <v-card>
        <v-card-title>
          <v-icon left>mdi-gavel</v-icon>
          <span v-if="dialog === 'new'" class="text-h5">Új büntetés kiírása</span>
          <span v-if="dialog === 'edit'" class="text-h5">Büntetés szerkesztése</span>
        </v-card-title>

        <v-divider class="mb-6"/>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-datetime-picker
                v-model="penalty.start_date"
                label="Kezdete"
                required
                filled
                :timePicker="false"
                :error-messages="errors.start_date"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="penalty.interval"
                label="Időtartam"
                filled
                :items="[
                  { value: '1 week', text: '1 hét' },
                  { value: '2 week', text: '2 hét' },
                  { value: '3 week', text: '3 hét' },
                  { value: '1 month', text: '1 hónap' },
                ]"
                :error-messages="errors.interval"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="penalty.reason"
                label="Indoklás"
                :rows="3"
                auto-grow
                hint="A büntetett kártya tulajdonosa is megkapja e-mailben."
                persistent-hint
                filled
                required
                :error-messages="errors.reason"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Mégse</v-btn>
          <v-btn color="error" depressed :disabled="loading" :loading="loading" @click="save">
            Büntetés!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <!-- <v-row>
    <v-col :cols="12" :md="6">
      <v-card outlined>
        <v-card-title>
          Új büntetés
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="12" :md="6" >
              <v-datetime-picker
                v-model="penalty.start_date"
                label="Kezdete"
                required
                :timePicker="false"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col :cols="12" :md="6">

    </v-col>


  </v-row> -->
</template>

<script>
export default {
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      headers: [
        { text: 'Státusz', value: 'status', sortable: false },
        { text: 'Kezdete', value: 'start_date' },
        { text: 'Vége', value: 'end_date' },
        { text: 'Kiírás ideje', value: 'created' },
        { text: 'Büntetés oka', value: 'reason', sortable: false },
        { text: 'Adminisztrálta', value: 'admin', sortable: false },
        { value: 'actions', align: 'right', sortable: false },
      ],
      penalty: {
        start_date: '',
        interval: '1 month',
        reason: '',
      },
      errors: {},
      parkingPenalties: [],
      dialog: false,
      loading: false,
    };
  },

  mounted() {
    this.fetchPenalties();
  },

  methods: {
    openPenaltyDialog() {
      this.dialog = 'new';
    },

    async fetchPenalties() {
      this.loading = true;
      try {
        const response = await this.$http.post('parking-penalties/list', {
          card_id: this.$route.params.id
        });
        this.parkingPenalties = response.parking_penalties;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async save() {
      this.loading = true;
      this.errors = {};
      try {
        const response = await this.$http.post('parking-penalties/save', {
          card_id: this.$route.params.id,
          ...this.penalty,
        });
        if (response.errors) {
          this.errors = response.errors;
        } else {
          this.dialog = false;
          this.penalty = {
            start_date: '',
            interval: '1 month',
            reason: '',
          };
          this.fetchPenalties();
          this.$dialog.notify.success('Sikeres mentés');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    // async editParkingPenalty(data) {
    //   this.errors = [];
    //   try {
    //     // const datapost = {
    //     //   id: data.id,
    //     //   start_date: data.start_date,
    //     //   end_date: data.end_date,
    //     //   reason: data.reason,
    //     // };
    //     const response = await this.$http.post('parking_penalties/edit/');
    //     if (response.errors.length > 0) {
    //       this.errors = response.errors;
    //     } else {
    //       this.getLatestParkingPenalties();
    //     }
    //   } catch (e) {
    //     console.error(e);
    //   }
    // },


    async cancel(item) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `A büntetés visszavonására készül!<br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) return;

      try {
        await this.$http.post('parking-penalties/cancel', {
          id: item.id,
        });
        this.fetchPenalties();
        this.$dialog.notify.info('A büntetés visszavonva');
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
