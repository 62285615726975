import router from '@/router';
import store from '@/store';

const routes = [
  {
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/variants/sze/pages/login/Login.vue'),
    meta: {
      background: 'variants/marcussecurity/assets/login-bg.jpg',
      logo: 'variants/sze/assets/logo-wide-dark.svg',
      subtitle: 'Széchenyi Egyetem beléptető adminisztrációs felület',
      title: 'Bejelentkezés'
    },
  },
  {
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@/variants/sze/pages/home/Home.vue'),
  },
  {
    name: 'CardList',
    component: () => import(/* webpackChunkName: "CardList" */ '@/variants/sze/pages/cards/List.vue'),
  },
  {
    name: 'CardEditor',
    component: () => import(/* webpackChunkName: "CardEditor" */ '@/variants/sze/pages/cards/Editor.vue'), 
  },
  {
    name: 'NewCard',
    component: () => import(/* webpackChunkName: "CardEditor" */ '@/variants/sze/pages/cards/Editor.vue'),
  },
];

router.$addRoutes(routes);

export default router;
