<template>
  <router-link :to="{ name: routeName }">
    <v-sheet rounded color="transparent" min-height="36px" class="mx-3 my-1 text-body-2">
      <v-sheet
        min-height="36px"
        rounded
        color="transparent"
        class="menu-item px-3 pa-1 relative d-flex align-center font-weight-medium"
        :class="{
          'active': isActive,
          'has-children': hasChildren,
          'grey--text text--lighten-5': !isActive && $store.state.darkMode,
          'text--text text--darken-4': !isActive && !$store.state.darkMode,
          dark: $store.state.darkMode,
        }"
        v-ripple
      >
        <v-icon v-if="!hideIcon" left size="20">
          {{ item.icon }}
        </v-icon>

        <span class="px-2">{{ item.label }}</span>

        <v-btn v-if="hasChildren" icon small right class="ml-auto">
          <v-icon @click.native.prevent="toggle"> mdi-chevron-down </v-icon>
        </v-btn>
      </v-sheet>

      <v-sheet
        v-if="hasChildren"
        :class="{ open }"
        :style="{
          height: childrenHeight,
        }"
        class="children ml-5"
        rounded
        color="transparent"
      >
        <sidebar-menu-item v-for="(item, index) in item.children" :key="'sidebar-menu-item-' + index" :item="item" hide-icon />
      </v-sheet>
    </v-sheet>
  </router-link>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      open: false,
    };
  },

  methods: {
    matchRoute(name) {
      if (!name) {
        return false;
      }
      return !!this.$route.matched.filter((e) => e.path && e.name === name).length;
    },

    matchChildRoute(name) {
      return !!this.$route.matched.filter((e) => e.path && e.name === name).length;
    },

    toggle() {
      this.open = !this.open;
    },
  },

  computed: {
    isActive() {
      return this.item.children?.length
        ? this.matchRoute(this.item.group)
        : this.matchRoute(this.item.to?.name);
    },

    routeName() {
      if (this.item.type === 'heading') {
        return false;
      }
      if (this.item?.to?.name) {
        return this.item.to.name;
      } else {
        return this.item.children[0].to.name;
      }
    },

    hasChildren() {
      return !!this.item.children?.length;
    },

    childrenHeight() {
      return this.open ? this.item.children.length * 40 + 'px' : '0px';
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item {
  transition: 0.25s all;
  font-size: 14px !important;
  color: var(--v-grey-darken2);
  &.dark {
    color: var(--v-grey-lighten2);
  }

  &.active {
    background-color: #0002 !important;
    color: var(--v-black);
    &.dark {
      background-color: #fff2 !important;
      color: var(--v-white);
    }
  }

  &:hover {
    margin-left: 2px;
    background-color: #8882 !important;
  }
}

.has-children.active {
  background: transparent !important;
  background-color: #8884 !important;
}

.children {
  position: relative;
  transition: 0.25s all;
  overflow-y: hidden;
  opacity: 0;
  transform: scaleY(0.9) translateY(-6px);
  transform-origin: top center;
  &.open {
    opacity: 1;
    transform: scaleY(1);
  }
  &::before {
    content: '';
    position: absolute;
    width: 1px;
    left: 2px;
    top: 4px;
    bottom: 4px;
    background-color: #8883;
  }
}
</style>
