// import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import emailMask from 'text-mask-addons/dist/emailMask';

export default {
  constants: {
    daysOfWeek: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
  },
  momentFormats: {
    explicit: 'YYYY MMMM Do hh:mm:ss (dddd)',
    shortDateTime: 'MMM DD hh:mm',
  },
  dataTableFooterProps: {
    itemsPerPageOptions: [10, 25, 50, 100],
    showCurrentPage: true,
    showFirstLastPage: true,
    // pagination: {
    //   page: 3,
    //   itemsPerPage: 10,
    //   pageStart: 3*10,
    //   pageStop: 4*10-1,
    //   pageCount: 100/10,
    //   itemsLength: 1000
    // }
  },
  inputMasks: {
    phoneNumber: '+## ## ### #### ####',
    cardNumber: '########',
    plateNumber: '----------',
    rfId: 'HHHHHHHH',
    barcodeRfId: 'HHHHHHHHHHHHHHHH',
    barcodeNumber: '##########',
    name: '________________________________________',
    password: '********************',
    time: timeMask,
    integerNumber: createNumberMask({
      includeThousandsSeparator: false,
      min: 1,
      max: 65535,
    }),
    floatNumber: createNumberMask({
      allowDecimal: true,
    }),
    gatewayAdddress: gatewayAdddressMask,
    ipAddress: ipAddressMask,
    email: emailMask,
    createNumberMask
  },
  buttonAttrs: {
    floating: {
      width: 60,
      height: 60,
      tile: true,
      depressed: true,
      // dark: true,
      color: 'primary',
      class: 'ml-1',
    },
  },
  comparators: {
    isEqual: (a, b) => a == b,
    isIdentical: (a, b) => a === b,
    isDirty: (a, b) => {
      a = JSON.parse(JSON.stringify(a));
      b = JSON.parse(JSON.stringify(b));

      const customizer = (a, b) => {
        // if (a instanceof Array || b instanceof Array) {
        //   return a.length == b.length && a.every((e, i) => e == b[i]);
        // }
        return vue.lodash.isEqual(a, b) || (a == b) || ((a == null && b == '') || (a == '' && b == null));
      };

      for (const [index, item] of Object.entries(a)) {
        for (const [key, field] of Object.entries(item)) {
          if (!vue.lodash.isEqualWith(field, b[index][key], customizer)) {
            return true;
          }
        }
      }
      return false;
    },
  },
};

export function timeMask(value) {
  const hours = [/[0-2]/, value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/];
  const minutes = [/[0-5]/, /[0-9]/];
  const seconds = [/[0-5]/, /[0-9]/];
  return value.length > 2 ? [...hours, ':', ...minutes, ':', ...seconds] : hours;
}

export function gatewayAdddressMask(value) {
  value = value.toLowerCase();
  var v = value.replace(/[^0-9a-z.]/g, '');
  return [v];
}

export function ipAddressMask(value = '') {
  // Remove any non-allowed characters if there is any
  if (value.match(/[^\d.]/)) {
    value = value.replace(/[^\d]/g, '');
  }

  // Remove leading zeros ()
  value = value.replace(/^[0]*/, '');

  // Return early if there is no value
  if (!value.length) {
    return value.split();
  }

  let segments = value.split('.');
  if (segments.length > 4) {
    segments = segments.splice(0, 4);
  }

  // Helper function to parse longer segments into shorter valid ones
  const getFirstValidSegment = (string) => {
    while (Number(string) > 255) {
      string = string.slice(0, -1);
    }
    return string;
  }

  // Iterate while last segment is not valid
  while (Number(segments[segments.length - 1]) > 255) {
    const lastSegment = segments[segments.length - 1];
    const s = getFirstValidSegment(lastSegment);
    if (segments.length < 4) { // If not the last segment, then add it before the last index of segments
      segments[Math.max(segments.length - 1, 0)] = lastSegment.substring(s.length, lastSegment.length);
      segments.splice(Math.max(segments.length - 1, 0), 0, s);
    } else { // If the last segment, then add it to the last index
      segments[Math.max(segments.length - 1, 0)] = s;
    }
  }

  // Join segments into a string, and remove leading zeros if any (with the Number constructor)
  let string = segments.map(e => e.length ? Number(e) : e).join('.');

  // Add separator if it's not the last segment and the next character would cause an overflow
  if (segments.length < 4 && Number(`${segments[segments.length - 1]}0`) > 255) {
    string += '.';
  }

  // Return string as array
  return string.split();
}


const minus = '-';
const minusRegExp = /-/;
const nonDigitsRegExp = /\D+/g;
const number = 'number';
const digitRegExp = /\d/;
const caretTrap = '[]';
export function createNumberMask(options) {
  const min = options.min || null;
  const max = options.max || null;
  const prefix = options.prefix || '';
  const suffix = options.suffix || '';
  const includeThousandsSeparator = options.includeThousandsSeparator || false;
  const thousandsSeparatorSymbol = options.thousandsSeparatorSymbol || ' ';
  const allowDecimal = options.allowDecimal || false;
  const decimalSymbol = options.decimalSymbol || '.';
  const decimalLimit = options.decimalLimit || 2;
  const requireDecimal = options.requireDecimal || false;
  const allowNegative = options.allowNegative || false;
  const allowLeadingZeroes = options.allowLeadingZeroes || false;
  const integerLimit = options.integerLimit || null;

  const prefixLength = (prefix && prefix.length) || 0;
  const suffixLength = (suffix && suffix.length) || 0;
  const thousandsSeparatorSymbolLength =
    (thousandsSeparatorSymbol && thousandsSeparatorSymbol?.length) || 0;

  function numberMask(rawValue = '') {
    const rawValueLength = rawValue.length;

    if (rawValue === '' || (rawValue[0] === prefix[0] && rawValueLength === 1)) {
      return prefix
        .split('')
        .concat([digitRegExp])
        .concat(suffix.split(''));
    } else if (rawValue === decimalSymbol && allowDecimal) {
      return prefix
        .split('')
        .concat(['0', decimalSymbol, digitRegExp])
        .concat(suffix.split(''));
    }

    const isNegative = rawValue[0] === minus && allowNegative;
    //If negative remove "-" sign
    if (isNegative) {
      rawValue = rawValue.toString().substr(1);
    }

    const indexOfLastDecimal = rawValue.lastIndexOf(decimalSymbol);
    const hasDecimal = indexOfLastDecimal !== -1;

    let integer;
    let fraction;
    let mask;

    // remove the suffix
    if (rawValue.slice(suffixLength * -1) === suffix) {
      rawValue = rawValue.slice(0, suffixLength * -1);
    }

    if (hasDecimal && (allowDecimal || requireDecimal)) {
      integer = rawValue.slice(
        rawValue.slice(0, prefixLength) === prefix ? prefixLength : 0,
        indexOfLastDecimal
      );

      fraction = rawValue.slice(indexOfLastDecimal + 1, rawValueLength);
      fraction = convertToMask(fraction.replace(nonDigitsRegExp, ''));
    } else {
      if (rawValue.slice(0, prefixLength) === prefix) {
        integer = rawValue.slice(prefixLength);
      } else {
        integer = rawValue;
      }
    }

    if (integerLimit && typeof integerLimit === number) {
      const thousandsSeparatorRegex =
        thousandsSeparatorSymbol === '.' ? '[.]' : `${thousandsSeparatorSymbol}`;
      const numberOfThousandSeparators = (
        integer.match(new RegExp(thousandsSeparatorRegex, 'g')) || []
      ).length;

      integer = integer.slice(
        0,
        integerLimit + numberOfThousandSeparators * thousandsSeparatorSymbolLength
      );
    }

    if (max && typeof max === number && Number(integer) > max) {
      integer = integer.substring(0, integer.length - 1);
    }

    if (min && typeof min === number && Number(integer) < min) {
      integer = String(min);
    }

    integer = integer.replace(nonDigitsRegExp, '');

    if (!allowLeadingZeroes) {
      integer = integer.replace(/^0+(0$|[^0])/, '$1');
    }

    integer = includeThousandsSeparator
      ? addThousandsSeparator(integer, thousandsSeparatorSymbol)
      : integer;

    mask = convertToMask(integer);

    if ((hasDecimal && allowDecimal) || requireDecimal === true) {
      if (rawValue[indexOfLastDecimal - 1] !== decimalSymbol) {
        mask.push(caretTrap);
      }

      mask.push(decimalSymbol, caretTrap);

      if (fraction) {
        if (typeof decimalLimit === number) {
          fraction = fraction.slice(0, decimalLimit);
        }

        mask = mask.concat(fraction);
      }

      if (requireDecimal === true && rawValue[indexOfLastDecimal - 1] === decimalSymbol) {
        mask.push(digitRegExp);
      }
    }

    if (prefixLength > 0) {
      mask = prefix.split('').concat(mask);
    }

    if (isNegative) {
      // If user is entering a negative number, add a mask placeholder spot to attract the caret to it.
      if (mask.length === prefixLength) {
        mask.push(digitRegExp);
      }

      mask = [minusRegExp].concat(mask);
    }

    if (suffix.length > 0) {
      mask = mask.concat(suffix.split(''));
    }

    return mask;
  }

  numberMask.instanceOf = 'createNumberMask';

  return numberMask;
}

function convertToMask(strNumber) {
  return strNumber.split('').map(char => (digitRegExp.test(char) ? digitRegExp : char));
}

// http://stackoverflow.com/a/10899795/604296
function addThousandsSeparator(n, thousandsSeparatorSymbol) {
  return n.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparatorSymbol);
}
