/* eslint-disable no-unused-vars */
import 'babel-polyfill';
import "@/components";
import "@/variants/sze/components";
import "@/directives";
import "@/filters";
import Vue from 'vue';
import '@/mixins';
import App from '@/App.vue';
import store from '@/store';
import './menu';
import http from "@/http";
Vue.prototype.$http = http;
import router from './router';
import { vuetify } from '@/plugins';
import colors from 'vuetify/lib/util/colors';
Vue.prototype.$dialogs = {};
import axios from 'axios';

// import '@/components';

// import swal from 'sweetalert';

import config from '@/config';
import variantConfig from './config';
Vue.prototype.$config = { ...config, ...variantConfig };


vuetify.framework.theme.themes['dark'] = {
  primary: '#01a8c8',
  secondary: '#1c2442',
  accent: '#01a8c8',
  // white: colors.grey.darken4,
  grey: colors.grey.darken3,
  muted: '#333',
  success: colors.green.darken1,
  error: colors.red.base,
  warning: colors.amber.darken3,
  text: colors.white,
};
vuetify.framework.theme.themes['light'] = {
  primary: '#1c2442',
  secondary: '#1c2442',
  accent: '#01a8c8',
  // white: colors.white.base,
  // grey: colors.grey.base,
  muted: '#bbb',
  success: colors.green.darken2,
  error: colors.red.darken1,
  warning: colors.amber.darken3,
  text: colors.black,
};

Vue.config.productionTip = false;
// Vue.config.silent = true;

Vue.prototype.$logout = async ({ message, redirect }) => {
  await window.vue.$http.get('auth/logout');
  try {
    await axios.default.get('https://belepteto.sze.hu/shib_gate/logout.php');
  } catch (e) {
    console.error(e);
  }
  window.vue.$router.push({ name: 'Login', params: { message, redirect: redirect || window.vue.$route.fullPath } });
};



window.vue = new Vue({
  data: {
    version: '2021.01.24.22.50',
    user: {},
    pageMsg: {
      type: '',
      text: ''
    },
    socket: ''
  },
  methods: {
    checkRootUserHasSet(cb) {
      cb();
    },
    smallButtonMenu() {
      Vue.nextTick(() => {
        $(document).on('click', '.small-button.with-menu', function(e) {
          e.preventDefault();
          $('.small-button-menu').not($(this).next('.small-button-menu')).removeClass('open');
          $(this).next('.small-button-menu').toggleClass('open');
        });

        $(document).on('click', function(e) {
          if (!$(e.target).hasClass('customicon') && !$(e.target).hasClass('small-button')) {
            $('.small-button-menu').removeClass('open');
          }
        });
      });
    },

    generateGUID: function() {
        function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    },
    ajax: function(url, user_options) {
      var _this = this;

      // Verzió ellenőrzés
      $.ajax({
        url: process.env.VUE_APP_API_URL + 'getVersion/' + _this.version,
        dataType: 'json',
        success: function(response) {
          if (response.status == 'ERROR') {
            swal('Figyelem', response.msg, 'error');
            return;
          } else {
            _this.callajax(url, user_options);
          }
        }
      });
    },
    callajax: function(url, user_options) {
      var _this = this;

      var options = {
        method: 'GET',
        dataType: 'json',
        data: {},
        async: true,
        success: function() {},
        error: function() {},
        waitForSaveInfo: false
      };

      options = $.extend({}, options, user_options);

      try {
        if (url == undefined)
          throw 'Url hiányzik';

        $.ajax({
          method: options.method,
          url: process.env.VUE_APP_API_URL + url,
          async: options.async,
          data: options.data,
          //cache: false,
          timeout: 1800000,
          dataType: options.dataType,
          error: options.error,
          beforeSend: options.beforeSend ? options.beforeSend : ()=>{},
          success: function (response) {
            if (response.status == 'OK') {
              setTimeout(function() {
                options.success(response);
              }, options.waitForSaveInfo ? 500 : 0);

            } else if (response.status == 'NO_ACCESS') {
              const params = {
                type: 'error',
                text: 'Nincs joga a művelethez'
              };
              _this.$router.push({ name: 'Message', params });

            } else if (response.status == 'NO_LOGGED_USER') {
              const params = {
                type: 'error',
                text: 'Nem található bejelentkezett felhasználó.'
              };
              _this.$router.push({ name: 'Message', params });

            } else if (response.status == 'NO_API_KEY') {
              const params = {
                type: 'error',
                text: 'Nem található API kulcs'
              };
              _this.$router.push({ name: 'Message', params });

            } else if (response.status == 'NO_VALID_API_KEY') {
              const params = {
                type: 'error',
                text: 'Érvénytelen az API kulcs'
              };
              _this.$router.push({ name: 'Message', params });

            } else if (response.status == 'ERROR') {
              const params = {
                type: 'error',
                text: response.msg
              };
              _this.$router.push({ name: 'Message', params });

            } else if (response.status == 'DATABASE_ERROR') {
              const params = {
                type: 'error',
                text: response.text
              };
              _this.$router.push({ name: 'Message', params });
            }
          }
        });

      } catch(error) {
        alert(error);
      }
    }
  },
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
